$color-1: #2496FF
$color-2: #55D171
$color-black: #0F1726
$color-white: #E6E9ED

@import url('https://use.typekit.net/zmc8vly.css')

%font-regular
  font-family: degular-display, sans-serif
  font-weight: 400
  font-style: normal
  letter-spacing: .1ch

%font-title
  font-weight: 500
  line-height: .75

%top
  top: 0
  left: 0
  width: 100%
  
%fixed
  @extend %top
  
  position: fixed
  
%absolute
  @extend %top
  
  position: absolute
  
*
  margin: 0
  padding: 0
  box-sizing: border-box

body
  @extend %font-regular

  width: 100%
  height: 100vh
  overscroll-behavior: none
  color: $color-white
  background-color: $color-black
  line-height: 1.2
  
  &::-webkit-scrollbar
    display: none

h1,
h2
  @extend %font-title
     
canvas.webgl
  @extend %fixed

  pointer-events: none
  mix-blend-mode: screen

  @media (max-width: 64em)
      opacity: .7

.scroll
  &__stage
    @extend %fixed

    height: 100vh

    &.js-mobile
      @extend %absolute
      
      height: auto

      .scroll__content
        position: relative
  
  &__content
    @extend %absolute

    will-change: transform

.section
  display: flex
  justify-content: space-evenly
  align-items: center
  width: 100%
  min-height: calc(100vh - 7rem)
  padding: 8rem 
  
  @media (max-width: 64em)
    flex-direction: column
    justify-content: center
    min-height: 100vh
    padding: 10vh 2rem

    &:first-of-type
        gap: 0

  &:not(:first-of-type)
    .section__title
      padding-bottom: calc(min(9vw, 6rem))

  &:nth-child(even)
    flex-direction: row-reverse
    background: $color-1
    
    .section__title
      text-align: right

    @media (max-width: 64em)
      flex-direction: column

      .section__paragraph
        align-self: flex-start

  &__title
    text-align: left
    text-transform: uppercase
    opacity: .9

    @media (max-width: 64em)
      width: 100%
    
    &-number
      font-size: min(36vw, 24rem)
      
    &-text
      font-size: min(18vw, 12rem)
      padding-left: .1ch
        
    &-arrow
      font-size: min(9vw, 6rem)
      
      color: $color-1
      margin-top: 1ch
      line-height: 1
      
      span
        display: inline-block
        transform: rotate(90deg)
    
  &__paragraph
    align-self: flex-end
    max-width: 20ch
    font-size: 1.4rem

    @media (max-width: 64em)
      font-size: 1.2rem
    
  &__button
    @extend %font-regular

    display: inline-block
    margin: 5ch 0
    padding: 2ch 5ch
    border: 2px solid currentColor
    border-radius: 50%
    font-size: .8em
    text-transform: uppercase
    letter-spacing: .1ch
    transition: background-color 1s ease
    cursor: pointer

    &:hover,
    &:focus
      border: 2px solid $color-2
     
.layout
  &__line
    @extend %fixed
    
    height: 5px
    width: 100%
    background-color: $color-white
    transform: scaleX(0)
  
  &__faboolea
    position: fixed
    bottom: 3rem
    height: .5rem
    width: 100%
    text-align: center
