@import "https://use.typekit.net/zmc8vly.css";
.section__button, body {
  letter-spacing: .1ch;
  font-family: degular-display, sans-serif;
  font-style: normal;
  font-weight: 400;
}

h1, h2 {
  font-weight: 500;
  line-height: .75;
}

.scroll__content, .scroll__stage.js-mobile, .layout__line, .scroll__stage, canvas.webgl {
  width: 100%;
  top: 0;
  left: 0;
}

.layout__line, .scroll__stage, canvas.webgl {
  position: fixed;
}

.scroll__content, .scroll__stage.js-mobile {
  position: absolute;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  overscroll-behavior: none;
  color: #e6e9ed;
  background-color: #0f1726;
  width: 100%;
  height: 100vh;
  line-height: 1.2;
}

body::-webkit-scrollbar {
  display: none;
}

canvas.webgl {
  pointer-events: none;
  mix-blend-mode: screen;
}

@media (width <= 64em) {
  canvas.webgl {
    opacity: .7;
  }
}

.scroll__stage {
  height: 100vh;
}

.scroll__stage.js-mobile {
  height: auto;
}

.scroll__stage.js-mobile .scroll__content {
  position: relative;
}

.scroll__content {
  will-change: transform;
}

.section {
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  min-height: calc(100vh - 7rem);
  padding: 8rem;
  display: flex;
}

@media (width <= 64em) {
  .section {
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;
    padding: 10vh 2rem;
  }

  .section:first-of-type {
    gap: 0;
  }
}

.section:not(:first-of-type) .section__title {
  padding-bottom: min(9vw, 6rem);
}

.section:nth-child(2n) {
  background: #2496ff;
  flex-direction: row-reverse;
}

.section:nth-child(2n) .section__title {
  text-align: right;
}

@media (width <= 64em) {
  .section:nth-child(2n) {
    flex-direction: column;
  }

  .section:nth-child(2n) .section__paragraph {
    align-self: flex-start;
  }
}

.section__title {
  text-align: left;
  text-transform: uppercase;
  opacity: .9;
}

@media (width <= 64em) {
  .section__title {
    width: 100%;
  }
}

.section__title-number {
  font-size: min(36vw, 24rem);
}

.section__title-text {
  padding-left: .1ch;
  font-size: min(18vw, 12rem);
}

.section__title-arrow {
  color: #2496ff;
  margin-top: 1ch;
  font-size: min(9vw, 6rem);
  line-height: 1;
}

.section__title-arrow span {
  display: inline-block;
  transform: rotate(90deg);
}

.section__paragraph {
  align-self: flex-end;
  max-width: 20ch;
  font-size: 1.4rem;
}

@media (width <= 64em) {
  .section__paragraph {
    font-size: 1.2rem;
  }
}

.section__button {
  text-transform: uppercase;
  letter-spacing: .1ch;
  cursor: pointer;
  border: 2px solid;
  border-radius: 50%;
  margin: 5ch 0;
  padding: 2ch 5ch;
  font-size: .8em;
  transition: background-color 1s;
  display: inline-block;
}

.section__button:hover, .section__button:focus {
  border: 2px solid #55d171;
}

.layout__line {
  background-color: #e6e9ed;
  width: 100%;
  height: 5px;
  transform: scaleX(0);
}

.layout__faboolea {
  text-align: center;
  width: 100%;
  height: .5rem;
  position: fixed;
  bottom: 3rem;
}

/*# sourceMappingURL=index.06c24ce8.css.map */
